import { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import SubcategorySection from "./components/SubcategorySection";
import { AcademyPost, AcademyPostResponseModel } from "../../models/academyPostResponseModel";
import { AcademyCategories, CategoryResponseModel, getAcademyCategoriesAxios } from "../../service/categoryService";
import { getAcademyPostsByMainCategoryAxios } from "../../service/postService";
import './posts.css';
import { useFetcher, useLocation } from "react-router-dom";



const PostsPage = () => {
    const [categories, setCategories] = useState<CategoryResponseModel[]>();
    const [mainCategories, setMainCategories] = useState<CategoryResponseModel[]>();
    const [subCategories, setSubCategories] = useState<CategoryResponseModel[]>();
    const [dropdownTitle, setDropdownTitle] = useState<string>("Choose main category");
    const [chosenCategory, setChosenCategory] = useState<CategoryResponseModel>();
    const [showPosts, setShowPosts] = useState(false);
    const [posts, setPosts] = useState<AcademyPost[]>();

    

    useEffect(() => {
        fetchCategories();
    }, []);

    /*
    useEffect(() => {

    }, [chosenCategory]); */

    const fetchCategories = async () => {
        try {
            const res = await getAcademyCategoriesAxios();
            const categories = res?.data?.academy_categories; 
            setCategories(categories);
            const mainCategories: CategoryResponseModel[] = [];
            categories?.forEach(cat => {
                if (!cat.parent_category_id){
                    mainCategories.push(cat); 
                }
            });
            setMainCategories(mainCategories);
        } catch (e: any) {
            throw new Error(e.message);
        }
    }

    const handleMainCategory = (mainCategory: CategoryResponseModel) => {
        setDropdownTitle(mainCategory.title);
        setChosenCategory(mainCategory);
        localStorage.setItem('category_id', mainCategory.id);
        fetchPostsForMainCategory(mainCategory);
        const subCategories: CategoryResponseModel[] = [];
        categories?.forEach(category => {
            if (category.parent_category_id === mainCategory.id){
                subCategories.push(category);
            }
        });
        setSubCategories(subCategories);
    }

    const fetchPostsForMainCategory = async (cat: CategoryResponseModel) => {
        try {
            const res = await getAcademyPostsByMainCategoryAxios(cat.id);
            console.log(res?.data);
            const posts = res?.data; 
            setPosts(posts);
            console.log(posts?.length);
            console.log(posts[0]?.title);
        } catch (err: any) {
            console.error(err.message);
        }
    }


    return (
        <div>
            <div className="container">
                <p>ACADEMY POSTS</p>
                <hr></hr>
            </div>
            <div id='dropdownDiv'>
                <DropdownButton title={dropdownTitle} variant={'info'} id={'categoryDropdown'} defaultValue={chosenCategory?.title}>
                    {mainCategories?.map((cat, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleMainCategory(cat)}
                                eventKey={cat.title}
                                required
                                style={{width: '100%'}}
                                >
                                {cat.title}
                            </Dropdown.Item>
                        )
                    })}
                </DropdownButton>
            </div>
            {posts && (
                <div>
                    {subCategories?.map(cat => {
                        return (
                            <div>
                                <SubcategorySection posts={posts} subCategory={cat} key={cat.id} />
                            </div>
                            )}    
                        )}  
                </div>
            )}
        </div>
    )
}

export default PostsPage;