import { useEffect, useState } from "react";
import { AcademyPost } from "../../../models/academyPostResponseModel"
import PostCard from "./PostCard";
import '../posts.css';

type Props = {
    posts: AcademyPost[];
}

const ListOfPosts = ({posts}: Props) => {
    const [deletedPost, setDeletedPost] = useState<string>('');
    useEffect(() => {
        const index = posts.findIndex((element) => element.id === deletedPost);
        posts.splice(index, 1);
    }, [deletedPost]);

    const removePost = (postId: string) => {
        setDeletedPost(postId);
    }

    return (
        <div className="listOfPosts">
            {posts.map(post => {
                return (
                    <PostCard post={post} removePost={removePost} key={post.id} />
                )
            })}
        </div>
    )
}

export default ListOfPosts;