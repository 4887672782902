import { AcademyPost } from "../../../models/academyPostResponseModel";
import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { deleteAcademyPostAxios } from "../../../service/postService";
import { FiEye } from "react-icons/fi";
import { Rating } from "@mui/material";
import Badge from "react-bootstrap/Badge";
import dateFormat, { masks } from "dateformat";

type Props = {
  post: AcademyPost;
  removePost: (postId: string) => void;
};

const PostCard = ({ post, removePost }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteSuccesful, setDeleteSuccesful] = useState<boolean>(false);

  useEffect(() => {}, [post]);

  const handleClickDelete = () => setShowDeleteModal(true);

  const handleDeletePost = async (postId: string) => {
    try {
      const res = await deleteAcademyPostAxios(postId);
      removePost(postId);
      console.log(res?.data);
      setShowDeleteModal(false);
      setDeleteSuccesful(true);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <>
      <Card style={{ width: "20rem", marginLeft: "10px", marginTop: "5px" }}>
        <Card.Text style={{ opacity: 0.5, margin: 5 }}>
          {dateFormat(post?.created_at, "dd.mm.yyyy")}
        </Card.Text>
        <Card.Img
          src={
            post?.media_content?.coverImage[0]?.url ??
            "https://pbs.twimg.com/media/FhZPv61WIAI3LCl.jpg"
          }
          style={{
            height: "auto",
            width: "95%",
            margin: "auto",
            resize: "both",
          }}
        />
        <Card.Title style={{ marginLeft: "10px" }}>{post?.title}</Card.Title>
        {post?.subscription_type?.type === "premium" && (
          <Badge pill bg="warning" style={{ width: 70, marginLeft: 5 }}>
            Premium
          </Badge>
        )}
        <Card.Body>
          <Card.Text>{post?.description}</Card.Text>
          <Card.Text>
            <Rating
              name="half-rating-read"
              defaultValue={post?.rating ?? 0}
              precision={0.5}
              readOnly
            />
          </Card.Text>
          <Card.Text>
            <FiEye size={16} style={{ marginRight: 5 }} />
            {post?.views}
          </Card.Text>
          <Link to={"/edit"} state={{ data: post }}>
            <Button className="updateButton">Edit post</Button>
          </Link>
          <Button className="deleteButton" onClick={() => handleClickDelete()}>
            Delete
          </Button>
        </Card.Body>
      </Card>
      <Modal show={showDeleteModal} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete post?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this post?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#1C4F5A",
              borderColor: "#1C4F5A",
              color: "#FFFFFF",
              width: "30%",
            }}
            variant="info"
            onClick={() => handleDeletePost(post.id)}
          >
            Yes
          </Button>
          <Button
            style={{
              backgroundColor: "#1C4F5A",
              borderColor: "#1C4F5A",
              color: "#FFFFFF",
              width: "30%",
            }}
            variant="info"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSuccesful} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Post deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The post was successfully deleted</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#1C4F5A",
              borderColor: "#1C4F5A",
              color: "#FFFFFF",
              width: "30%",
            }}
            variant="info"
            onClick={() => setDeleteSuccesful(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PostCard;
