import { useEffect, useState } from "react";
import { AcademyPost } from "../../../models/academyPostResponseModel"
import { CategoryResponseModel } from "../../../service/categoryService";
import ListOfPosts from "./ListOfPosts";

type Props = {
    posts: AcademyPost[];
    subCategory: CategoryResponseModel;
}

const SubcategorySection = ({posts, subCategory}: Props) => {
    const [postsForSubcategory, setPostsForSubcategory] = useState<AcademyPost[]>();

    useEffect(() => {
        getPostsForSubcategory();
    }, [posts])

    const getPostsForSubcategory = () => {
        const postsForSub: AcademyPost[] = [];
        posts?.forEach(post => {
            if (post.category.id === subCategory.id){
                postsForSub.push(post);
            }
        });
        setPostsForSubcategory(postsForSub);
    }

    if (postsForSubcategory?.length === 0){
        return null; 
    }

    return (
        <div>
            <div className={'subCategoryTitle'}>
                {subCategory.title}
            </div>
            {postsForSubcategory && (
                <div>
                    <ListOfPosts posts={postsForSubcategory}/>
                </div>
            )}
        </div>
    )
}

export default SubcategorySection;