import apiClient from "../api/client";

export interface CategoryResponseModel {
    id: string; 
    parent_category_id: string; 
    title: string; 
    thumbnail: string; 
}
export interface AcademyCategories {
    academy_categories: CategoryResponseModel[];
}

export async function getAcademyCategoriesAxios() 
{
    return await apiClient.get<AcademyCategories>("academy/categories");
}
