import { Outlet, Link } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import logo from '../../assets/companylogo.png';
import apiClient from "../../api/client";
import SignInButton from "../../components/SignInButton";



import { useAccount, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";

import './home.css';
import { hover } from '@testing-library/user-event/dist/hover';
import { useState, useEffect } from 'react';
import UploadPage from '../upload/upload';


const HomePage = ({accountId}) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    //const [accountId, setaccountId] = useState();
    const [adminAccess, setadminAccess] = useState();
    const [uploadclick, setUploadClick] = useState(false);
    const [apiData, setApiData] = useState(null);


    const [loading, setloading] = useState(false);

    const handleUploadScreen = ()=> {
        setUploadClick(true);
        
    }


    if (accounts.length > 0) {
    
    return (
        <div className='container'>
            <div className='body-home'>
                <p>
                    Hello {accounts[0].name}!
                    </p>
            <div className='button-container'>
                <Link to={{
                    pathname:'/upload',
                    state: accountId,
                }}>
                    <Button  variant='info' style={{ width:"100%", margin:10, backgroundColor:"#B0D7E1", borderColor:"#B0D7E1" }}>Upload</Button>
                </Link>
                <Link to='/posts'> 
                    <Button variant='info' style={{width:"100%", margin:10, backgroundColor:"#B0D7E1", borderColor:"#B0D7E1" }}>See posts</Button>
                </Link>
            </div>
        </div>  
    </div>    
    )
}
}

export default HomePage;

