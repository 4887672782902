import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/home/home';
import UploadPage from './pages/upload/upload';
import { useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
import Image from 'react-bootstrap/Image';
import logo from './assets/companylogo.png';
import apiClient from "./api/client";

import Navbar from "react-bootstrap/Navbar";
import { b2cPolicies, protectedResources } from './authConfig';
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { LandingPage } from './pages/landing/LandingPage';

const App = ({instance}) => {
    //const { instance, inProgress } = useMsal();
   
    
    return (
        <MsalProvider instance={instance}>
            <LandingPage/>
        </MsalProvider>

    );
};

export default App;

