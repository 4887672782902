import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Image from 'react-bootstrap/Image';
import logo from '../../assets/companylogo.png';
import Navbar from "react-bootstrap/Navbar";

import { SignInButton } from "../../components/SignInButton";
import { SignOutButton } from "../../components/SignOutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { useEffect, useState } from "react";
import apiClient from "../../api/client";
import { BrowserRouter, Routes, Route , Link} from "react-router-dom";
import HomePage from "../home/home";
import UploadPage from "../upload/upload";
import PostsPage from "../posts/PostsPage";
import EditPostPage from "../posts/EditPostPage";


export const LandingPage = (props) => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const [accountId, setaccountId] = useState();
    const [adminAccess, setadminAccess] = useState(false);
    const [accountType, setAccountType] = useState();
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            fetchAccount();
        }
    }, [isAuthenticated]);

    const fetchAccount = async () => {
        const res = await apiClient.get("academy/account/" + accounts[0]?.localAccountId) ?? '';
        const accountType = res?.data?.accounts[0]?.account_type ?? '';
        setAccountType(accountType);
        if (accountType === "Admin") {
            setadminAccess(true);
        }
        const accountId = res?.data?.accounts[0]?.id ?? '';
        setaccountId(accountId);
        console.log(accountType);

    }


    return (
        <>
            <Navbar variant="light" bg="light">
                <Navbar.Brand>
                    <Link to="/">
                    <Image src={logo} alt="logo" className="Nav-logo"></Image></Link></Navbar.Brand>
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </Navbar>
            <div className='wrapper'>
                <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/" element={<HomePage accountId={accountId} />} />
                        <Route path="/posts" element={<PostsPage />} />
                        <Route path="/edit" element={<EditPostPage/>} />
                        <Route exact path='/upload' element={<UploadPage accountId={accountId} />} />
                    </Routes>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className='container'>
                        <p>You are not signed in! Please sign in.</p>
                    </div>
                </UnauthenticatedTemplate>
                <div className="push"></div>
                <footer className="footer"></footer>
            </div>
        </>
    );
};